import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Container } from "@mui/material";
import { supabase } from "../Components/supabaseClient";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase
        .from("webContactUs")
        .insert([{ name, email, message }])
        .select("*");
      if (error) {
        console.log("error", error);
      } else {
        console.log("data", data);
        setName("");
        setEmail("");
        setMessage("");
      }
    } catch (error) {
      console.log("error, error in sending message", error);
    }
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setDisableButton(name === "" || !emailRegex.test(email) || message === "");
  }, [name, email, message]);

  return (
    <Container maxWidth="md">
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", py: 8, minHeight: "80vh" }}>
        <Box sx={{ mb: 6, textAlign: "center" }}>
          <Typography variant="h3" sx={{ color: '#1e3a8a', fontWeight: 'bold', mb: 2 }}>
            Contact Us
          </Typography>
          <Typography variant="h5" sx={{ color: 'text.secondary', mb: 4 }}>
            We would love to hear from you
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "500px", gap: 3 }}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            id="name"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ '& .MuiOutlinedInput-root': { '&:hover fieldset': { borderColor: '#1e3a8a' } } }}
          />
          <TextField
            fullWidth
            id="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ '& .MuiOutlinedInput-root': { '&:hover fieldset': { borderColor: '#1e3a8a' } } }}
          />
          <TextField
            fullWidth
            id="message"
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{ '& .MuiOutlinedInput-root': { '&:hover fieldset': { borderColor: '#1e3a8a' } } }}
          />
          <Button
            type="submit"
            variant="contained"
            disabled={disableButton}
            sx={{
              bgcolor: '#4caf50',
              fontSize: '1.1rem',
              py: 1.5,
              fontWeight: 'bold',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              transition: 'transform 0.2s',
              '&:hover': {
                bgcolor: '#388e3c',
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 8px rgba(0,0,0,0.15)'
              },
              '&:disabled': {
                bgcolor: '#ccc',
              }
            }}
          >
            Send Message
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ContactUs;